<template>
  <div
    class="ir is-loading"
    :class="customWrapperClass"
    :style="((inlineIr && aspectRatio) ? `padding-bottom: ${(100 / aspectRatio)}%` : paddingBottom)"
  >
    <img
      v-if="image"
      :key="image"
      :alt="imageAlt"
      :class="imageClass"
      :data-src="imageUrl"
      :data-widths="imageWidths"
      class="lazyload"
      data-sizes="auto"
      tabindex="-1"
    >
    <transition
      v-show="(hoverImage && enableTransition)"
      name="image-fade"
    >
      <img
        :key="hoverImage"
        :alt="imageAlt"
        :class="imageClass"
        :data-src="hoverImageUrl"
        :data-widths="imageWidths"
        class="lazyload"
        data-sizes="auto"
        tabindex="-1"
      >
    </transition>
  </div>
</template>

<script>
import { 
  computed, 
  onMounted, 
  ref
 } from 'vue';
export default {
  name: 'ResponsiveImage',
  props: {
    image: {
      default: null,
      required: false,
      type: String,
    },
    imageAlt: {
      default: '',
      required: false,
      type: String,
    },
    imageClass: {
      default: null,
      required: false,
      type: String,
    },
    inlineIr: {
      default: null,
      required: false,
      type: Boolean,
    },
    modifier: {
      default: null,
      required: false,
      type: String,
    },
    paddingBottom: {
      default: null,
      required: false,
      type: String,
    },
    wrapperClass: {
      default: null,
      required: false,
      type: String,
    },
    enableTransition: {
      default: false,
      required: false,
      type: Boolean
    },
    hoverImage: {
      default: null,
      required: false,
      type: String
    },
    isHovered: {
      default: false,
      required: false,
      type: Boolean
    }
  },
  setup(props) {
    const imageWidths = '[180,360,540,720,900,1080,1296,1512,1728,1944,2160,2376,2592,2808,3024]';
    const aspectRatio = ref(1);

    const customWrapperClass = computed(() => {
      let append = '';
      if (props.modifier) {
        append += `ir--${props.modifier}`;
      }
      if (props.wrapperClass) {
        append += ` ${props.wrapperClass}`;
      }
      return append;
    });
    const imageUrl = computed(() => {
      if (props.image?.includes('cdn.shopify.com')) {
        const returnImage = __.getSizedImageUrl(props.image, '1x1').replace('_1x1.', '_{width}x.');
        return returnImage;
      }
      // External image
      const image = props.image.replace(/(\.jpg)(\?.*)?$/, '_grande$1$2');
      return image;
    });

    const hoverImageUrl = computed(() => {
      if (!props.hoverImage) return '';
      
      if (props.hoverImage?.includes('cdn.shopify.com')) {
        const returnImage = __.getSizedImageUrl(props.hoverImage, '1x1').replace('_1x1.', '_{width}x.');
        return returnImage;
      }
      // External image
      const image = props.hoverImage.replace(/(\.jpg)(\?.*)?$/, '_grande$1$2');
      return image;
    });

    onMounted(() => {
      function getImageData() {
        const $image = new Image();
        let imageSrc;
        if (props.image?.includes('cdn.shopify.com')) {
          const returnImage = __.getSizedImageUrl(props.image, '1x1').replace('_1x1.', '_{width}x.');
          imageSrc = returnImage;
        }
        imageSrc = props.image;
        $image.src = imageSrc;
        $image.onload = (img) => {
          const height = img?.target?.naturalWidth;
          const width = img?.target?.naturalHeight;
          if (width && height) {
            aspectRatio.value = height / width;
          }
        };
        $image.onerror = (err) => {
          console.error(err);
          aspectRatio.value = 1;
        };
      }
      if (props.inlineIr) {
        getImageData();
      }
    });

    return {
      aspectRatio,
      customWrapperClass,
      imageWidths,
      imageUrl,
      hoverImageUrl
    };
  }
};
</script>

<style scoped>
  .image-fade-enter-active,
  .image-fade-leave-active {
    transition: opacity 0.4s ease-in-out 100ms, transform 0.4s ease-out 100ms;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .image-fade-enter-from,
  .image-fade-leave-to {
    opacity: 0;
  }

  .image-fade-enter-to,
  .image-fade-leave-from {
    opacity: 1;
  }
</style>